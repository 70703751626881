<template>
  <div class="container ma-tb-20">
		<div class="bg-fff setting-index">
			<back-button msg="电子病历"></back-button>
      <div class="fon-14" v-if="!$validator.isEmpty(detail)">
        <div class="flex f-center f-jcenter fon-18 font-bold">院前院内交接记录单</div>
        <div>分站：{{detail.Name}}</div>
        <div class="table-box">
          <div class="flex f-center f-between b-b-1-97 pa-5">
            <div class="">姓名：{{detail.Patient}}</div>
            <div class="">性别：{{detail.Gender}}</div>
            <div class="">年龄：{{detail.Age}}岁</div>
            <div class="">现场地址：{{detail.CallAddress}}</div>
          </div>
          <div class="flex f-center f-between b-b-1-97 pa-5">
            <div>送达时间：{{detail.ToHospitalTime}}</div>
            <div class="">送达医院：{{detail.HospitalName}}</div>
          </div>
          <div class="flex f-center b-b-1-97">
            <div class="pa-5 b-r-1-97 t-center">主诉</div>
            <div class="pa-5">{{detail.Symptom}}</div>
          </div>
          <div class="flex f-center f-between b-b-1-97">
            <div class="flex f-center width55">
              <div class="pa-5 b-r-1-97 t-center">初步印象</div>
              <div class="pa-5">{{detail.Preliminary}}</div>
            </div>
            <div class="flex f-center f-between width44">
              <div class="pa-5 b-r-1-97 b-l-1-97">病情判断</div>
              <div class="flex f-center pa-lr-10">
                <div class="iconfont pa-5" :class="detail.DiseaseDiagnosis == '1' ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                  <span class="pa-l-5">轻</span>
                </div>
                <div class="iconfont pa-5" :class="detail.DiseaseDiagnosis == '2' ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                  <span class="pa-l-5">中</span>
                </div>
                <div class="iconfont pa-5" :class="detail.DiseaseDiagnosis == '3' ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                  <span class="pa-l-5">重</span>
                </div>
                <div class="iconfont pa-5" :class="detail.DiseaseDiagnosis == '4' ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                  <span class="pa-l-5">危重</span>
                </div>
                <div class="iconfont pa-5" :class="detail.DiseaseDiagnosis == '5' ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                  <span class="pa-l-5">死亡</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex b-b-1-97">
            <div class="pa-5 b-r-1-97 flex f-center f-jcenter t-center title-tz">到院生命体征</div>
            <div class="pa-5 width100">
              <div class="pa-5 pa-lr-10 medical">体检：<span>{{detail.Medical_T}}</span>℃，P<span>{{detail.Medical_P}}</span>次/分，R<span>{{detail.Medical_R}}</span>次/分，BP <span>{{detail.Medical_BP}}</span> mmHg（kpa）</div>
              <div class="flex f-between pa-lr-5">
                <div class="flex f-center width50">
                  意识：
                  <div class="iconfont pa-5" :class="detail.Consciousness == '1' ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                    <span class="pa-l-5">正常</span>
                  </div>
                  <div class="iconfont pa-5" :class="detail.Consciousness == '2' ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                    <span class="pa-l-5">模糊</span>
                  </div>
                  <div class="iconfont pa-5" :class="detail.Consciousness == '3' ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                    <span class="pa-l-5">谵妄</span>
                  </div>
                  <div class="iconfont pa-5" :class="detail.Consciousness == '4' ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                    <span class="pa-l-5">昏迷</span>
                  </div>
                  <div class="iconfont pa-5" :class="detail.Consciousness == '5' ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                    <span class="pa-l-5">丧失</span>
                  </div>
                </div>
                <div class="flex f-center width50">
                  皮肤：
                  <div class="iconfont pa-5" :class="detail.Skin == '1' ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                    <span class="pa-l-5">正常</span>
                  </div>
                  <div class="iconfont pa-5" :class="detail.Skin == '2' ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                    <span class="pa-l-5">苍白</span>
                  </div>
                  <div class="iconfont pa-5" :class="detail.Skin == '3' ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                    <span class="pa-l-5">发红</span>
                  </div>
                  <div class="iconfont pa-5" :class="detail.Skin == '4' ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                    <span class="pa-l-5">黄染</span>
                  </div>
                  <div class="iconfont pa-5" :class="detail.Skin == '5' ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                    <span class="pa-l-5">青紫</span>
                  </div>
                </div>
              </div>
              <div class="flex f-between pa-lr-5">
                <div class="flex f-center width50">
                  瞳孔：
                  <div class="iconfont pa-5" :class="detail.Pupil == '1' ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                    <span class="pa-l-5">正常</span>
                  </div>
                  <div class="iconfont pa-5" :class="detail.Pupil == '2' ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                    <span class="pa-l-5">放大</span>
                  </div>
                  <div class="iconfont pa-5" :class="detail.Pupil == '3' ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                    <span class="pa-l-5">缩小</span>
                  </div>
                  <div class="iconfont pa-5" :class="detail.Pupil == '4' ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                    <span class="pa-l-5">不等</span>
                  </div>
                </div>
                <div class="flex f-center width50">
                  对光折射：
                  <div class="iconfont pa-5" :class="detail.LightReaction == '1' ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                    <span class="pa-l-5">正常</span>
                  </div>
                  <div class="iconfont pa-5" :class="detail.LightReaction == '2' ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                    <span class="pa-l-5">迟钝</span>
                  </div>
                  <div class="iconfont pa-5" :class="detail.LightReaction == '3' ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                    <span class="pa-l-5">消失</span>
                  </div>
                </div>
              </div>
              <div class="flex pa-lr-5">
                <div class="width20">必要记录：</div>
                <div class="">{{detail.HealthExamination}}
                </div>
              </div>
            </div>
          </div>
          <div class="flex b-b-1-97">
            <div class="pa-5 b-r-1-97 t-center">辅助检查</div>
            <div class="pa-5 medical">血糖：<span>{{detail.BloodSugar}}</span> mmol/L,&nbsp;&nbsp;&nbsp;血氧饱和度：<span>{{detail.SpO2}}</span>%</div>
          </div>
          <div class="flex b-b-1-97">
            <div class="flex width40">
              <div class="pa-5 b-r-1-97 flex f-center f-jcenter t-center title-tz">一般治疗</div>
              <div class="pa-tb-5 pa-l-5">
                <div class="iconfont pa-l-5" :class="detail.GeneralTreatment.indexOf('1') > -1 ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                  <span class="pa-l-5">吸氧</span>
                </div>
                <div class="iconfont pa-l-5 pa-t-5" :class="detail.GeneralTreatment.indexOf('2') > -1 ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                  <span class="pa-l-5">吸痰</span>
                </div>
                <div class="iconfont pa-l-5 pa-t-5" :class="detail.GeneralTreatment.indexOf('3') > -1 ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                  <span class="pa-l-5">心电监护</span>
                </div>
                <div class="iconfont pa-l-5 pa-t-5" :class="detail.GeneralTreatment.indexOf('4') > -1 ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                  <span class="pa-l-5">除颤、起搏</span>
                </div>
                <div class="iconfont pa-l-5 pa-t-5" :class="detail.GeneralTreatment.indexOf('5') > -1 ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                  <span class="pa-l-5">包扎止血</span>
                </div>
                <div class="iconfont pa-l-5 pa-t-5" :class="detail.GeneralTreatment.indexOf('6') > -1 ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                  <span class="pa-l-5">固定（康能、颈托）</span>
                </div>
                <div class="iconfont pa-l-5 pa-t-5" :class="detail.GeneralTreatment.indexOf('7') > -1 ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                  <span class="pa-l-5">人工呼吸（气囊、呼吸机）</span>
                </div>
                <div class="iconfont pa-l-5 pa-t-5" :class="detail.GeneralTreatment.indexOf('8') > -1 ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                  <span class="pa-l-5">气管插管</span>
                </div>
                <div class="iconfont pa-l-5 pa-t-5" :class="detail.GeneralTreatment.indexOf('9') > -1 ? 'icon-checkbox-ok' : 'icon-fuxuankuang_weixuanzhong'">
                  <span class="pa-l-5">冰袋冷敷</span>
                </div>
              </div>
            </div>
            <div class="flex width60">
              <div class="pa-5 b-r-1-97 b-l-1-97 flex f-center f-jcenter t-center title-tz">药物治疗</div>
              <div class="flex pa-5">
                <div>R:</div>
                <div>{{detail.DrugTreatment}}</div>
              </div>
            </div>
          </div>
          <div class="flex f-around pa-5">
            <div>急救医师：{{detail.Physician}}</div>
            <div>接诊医护人员：{{detail.MedicalStaff}}</div>
          </div>
        </div>
        <div class="flex f-center f-jcenter ma-b-20">注：本单一式两联，第一联粘贴于院前急救病历，第二联交予接诊医院保管</div>
      </div>
    </div>
  </div>
</template>

<script>
let _this;
export default {
  data () {   
    return {
			detail:''
    }
  },
  created(){
    _this = this;
		_this.loadData()
	},
	methods:{
		// 加载数据
		async loadData(){
			let _data = await _this.$request('/Task/v1/TaskEmergency/Get?taskId=' + _this.$route.query.id);
			if (_data.Code == 0) {
				_this.detail = _data.Data;
			}
		},
	}
}
</script>

<style lang="less" scoped>
  .setting-index{
    height: auto;
    .table-box{
      border: 1px solid #979797;
      margin: 5px auto;
      .width55{
        width: 55%;
      }
      .width44{
        width: 44%;
      }
      .pa-5{
        padding: 5px;
      }
      .pa-l-5{
        padding-left: 5px;
      }
      .pa-t-5{
        padding-top: 5px;
      }
      .pa-lr-5{
        padding-left: 5px;
        padding-right: 5px;
      }
      .pa-tb-5{
        padding-top: 5px;
        padding-bottom: 5px;
      }
      .b-r-1-97{
        border-right: 1px solid #979797;
      }
      .b-l-1-97{
        border-left: 1px solid #979797;
      }
      .title-tz{
        width: 15px;
      }
			.medical{
				span{
					border-bottom: .5px solid #000;
					padding: 0 8px;
					margin: 0 3px;
				}
			}
    }
  }
</style>>
